import { Prisma } from '@gn/db/client';
import { AccessType, ContentCategory } from '@gn/core/type';

export const itemsInRow = 10;
export const basedOnInterestsTitle = 'Based on your interests';
export const trendingContentTitle = 'Trending today';
export const featuredContentTitle = 'Featured';
export const latestContentTitle = 'Latest';

export interface ContentConfigItem {
    title: string;
    where?: Prisma.ContentWhereInput;
    orderBy?: Prisma.Enumerable<Prisma.ContentOrderByWithAggregationInput>;
    customRequest?: boolean;
    categories?: ContentCategory[];
    accesses?: AccessType[];
}
