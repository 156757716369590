import { ReactElement } from 'react';
import { trendingWorkshopsTitle, upcomingWorkshopsTitle } from './getWorkshopsPageContentConfig';
import { basedOnInterestsTitle, featuredContentTitle, latestContentTitle, trendingContentTitle } from './contentConfig';
import { BasedOnYourInterestsSlider } from '../core/components/BasedOnYourInterestsSlider';
import { ContentSlider } from '../core/components/ContentSlider';
import Link from 'next/link';
import { ContentCategory } from '@gn/core/type';
import { ContentListSchema } from '../core/components/content-page/seo/ContentListSchema';
import { Content } from '@gn/db';

export function ContentSliders({
    config,
    contents,
    idsFound,
    trendingContent,
    upcomingWorkshops,
    category,
    currentUser,
}): ReactElement {
    const sliders: JSX.Element[] = [];

    const contentsForSchema: Content[] = [];
    contents.forEach((contentArray, idx) => {
        if (config[idx].customRequest && config[idx].title === basedOnInterestsTitle) {
            if (currentUser && idsFound) {
                const interestsIds = currentUser.interests?.map((tag) => tag.id);
                sliders.push(
                    <BasedOnYourInterestsSlider
                        key={idx}
                        currentUser={currentUser}
                        interests={interestsIds}
                        idsToIgnore={idsFound}
                        categories={[category]}
                    />,
                );
            }
        } else if (
            config[idx].customRequest &&
            [trendingWorkshopsTitle, trendingContentTitle].includes(config[idx].title)
        ) {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...trendingContent);
            sliders.push(<ContentSlider key={idx} contents={trendingContent} title={currentTitle} />);
        } else if (upcomingWorkshops && config[idx].customRequest && config[idx].title === upcomingWorkshopsTitle) {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...upcomingWorkshops);
            sliders.push(
                <ContentSlider
                    key={idx}
                    contents={upcomingWorkshops}
                    title={currentTitle}
                    link={
                        <Link href="/workshops/upcoming" className="aside-link ttu">
                            All upcoming workshops
                        </Link>
                    }
                />,
            );
        } else if (config[idx].title === featuredContentTitle && category === ContentCategory.Workshop) {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...contentArray);
            sliders.push(
                <ContentSlider key={idx} contents={contentArray} title={currentTitle} highlightFeatured={false} />,
            );
        } else if (config[idx].title === latestContentTitle && category === ContentCategory.Workshop) {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...contentArray);
            sliders.push(
                <ContentSlider
                    key={idx}
                    contents={contentArray}
                    title={currentTitle}
                    link={
                        <Link href="/workshops/all" className="aside-link ttu">
                            All workshops
                        </Link>
                    }
                />,
            );
        } else if (idx === 0 && category === ContentCategory.Talk) {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...contentArray);
            sliders.push(
                <ContentSlider
                    key={idx}
                    contents={contentArray}
                    title={currentTitle}
                    highlightFeatured={false}
                    link={
                        <Link href="/talks/all" className="aside-link ttu">
                            All talks
                        </Link>
                    }
                />,
            );
        } else {
            const currentTitle = config[idx].title;
            contentsForSchema.push(...contentArray);
            sliders.push(<ContentSlider key={idx} contents={contentArray} title={currentTitle} />);
        }
    });
    return (
        <>
            <ContentListSchema contents={contentsForSchema} />
            {sliders}
        </>
    );
}
