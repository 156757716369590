import { addMonths, addYears } from 'date-fns';
import { ContentConfigItem, basedOnInterestsTitle, featuredContentTitle, latestContentTitle } from './contentConfig';
import { ContentCategory } from '@gn/core/type';

export const upcomingWorkshopsTitle = 'Attend Live Workshops';
export const trendingWorkshopsTitle = 'Trending';

export const getWorkshopsPageContentConfig: () => ContentConfigItem[] = () => {
    const halfYearAgo = addMonths(new Date(), -6);
    const yearAgo = addYears(new Date(), -1);

    return [
        {
            title: upcomingWorkshopsTitle,
            customRequest: true,
        },
        {
            title: latestContentTitle,
            where: {
                endDate: {
                    gte: halfYearAgo,
                },
            },
            categories: [ContentCategory.Workshop],
        },
        {
            title: featuredContentTitle,
            where: {
                featured: true,
                endDate: {
                    gte: yearAgo,
                },
            },
            categories: [ContentCategory.Workshop],
        },
        {
            title: trendingWorkshopsTitle,
            customRequest: true,
        },
        {
            title: basedOnInterestsTitle,
            customRequest: true,
        },
        {
            title: 'Popular',
            orderBy: [
                {
                    views: 'desc',
                },
            ],
            categories: [ContentCategory.Workshop],
        },
    ];
};
