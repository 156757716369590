import { ReactNode } from 'react';
import { PortalSlider } from './PortalSlider';
import { ContentListItem } from './content-list/ContentList';

export function ContentSlider({
    contents = [],
    title,
    messageWhenEmpty,
    link,
    openLinksInNewTab,
    bookmarkVisible,
    highlightFeatured,
}: {
    contents: any[];
    title: string;
    messageWhenEmpty?: string | ReactNode;
    link?: ReactNode;
    openLinksInNewTab?: boolean;
    bookmarkVisible?: boolean;
    highlightFeatured?: boolean;
}) {
    return (
        <PortalSlider items={contents} title={title} link={link} messageWhenEmpty={messageWhenEmpty}>
            {({ isImgLazyLoading, item }) => (
                <ContentListItem
                    skipWrapper={true}
                    content={item}
                    isImgLazyLoading={isImgLazyLoading}
                    openLinksInNewTab={openLinksInNewTab}
                    bookmarkVisible={bookmarkVisible}
                    highlightFeatured={highlightFeatured}
                />
            )}
        </PortalSlider>
    );
}
