import { trackEvent } from 'app/analytics';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const FIRST_SLIDERS_IMAGES_NOT_LAZY_LOADING = 4;
const FIRST_SLIDERS_IMAGES_NOT_LAZY_LOADING_MOBILE = 2;

type RenderChildren<T> = (props: { item: T; isImgLazyLoading: boolean }) => ReactNode;

interface PortalSlideProps<T> {
    items: T[];
    title: string;
    messageWhenEmpty?: ReactNode;
    link?: ReactNode;
    children: ReactNode | RenderChildren<T>;
    className?: string;
}

export function PortalSlider<T extends { id: string | number }>({
    items = [],
    title,
    messageWhenEmpty,
    link,
    children,
    className,
}: PortalSlideProps<T>) {
    const titleSlug = title.replace(/\W/g, '');
    SwiperCore.use([Navigation, Mousewheel]);

    const [activeIndex, setActiveIndex] = useState(0);

    const isMobileDevice = () => {
        if (typeof window === 'undefined') return true;
        return window.innerWidth < 768;
    };

    const isImageLazyLoading = (index: number) => {
        const prioritySliders = isMobileDevice()
            ? FIRST_SLIDERS_IMAGES_NOT_LAZY_LOADING_MOBILE
            : FIRST_SLIDERS_IMAGES_NOT_LAZY_LOADING;

        return index >= prioritySliders + activeIndex;
    };

    // if empty items and no fallback message
    if ((!items || !items.length) && !messageWhenEmpty) {
        return null;
    }

    return (
        <section className="w-section">
            <div className="aside-head">
                <div className="aside-title color">{title}</div>
                {link}
            </div>
            <div className={classNames(`${titleSlug}-today-swiper content-swiper`, className)}>
                {!items || !items.length ? (
                    <div>{messageWhenEmpty}</div>
                ) : (
                    <>
                        <Swiper
                            loop={false}
                            threshold={12}
                            slidesPerView="auto"
                            spaceBetween={0}
                            direction="horizontal"
                            mousewheel={{
                                forceToAxis: true,
                                thresholdDelta: 12,
                            }}
                            watchOverflow={true}
                            className="swiper-container"
                            navigation={{
                                nextEl: `.${titleSlug}-swiper__next`,
                                prevEl: `.${titleSlug}-swiper__prev`,
                            }}
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <div className="swiper-wrapper">
                                {items.map(
                                    (item, index) =>
                                        item && (
                                            <PortalSliderItem
                                                key={item.id}
                                                sliderTitleSlug={titleSlug}
                                                item={item}
                                                isImgLazyLoading={isImageLazyLoading(index)}
                                            >
                                                {children}
                                            </PortalSliderItem>
                                        ),
                                )}
                            </div>
                        </Swiper>

                        <div className={`swiper-button-prev ${titleSlug}-swiper__prev swiper-button-disabled`}>
                            <svg className="icon icon-arrow-left">
                                <use xlinkHref="/img/sprite.svg?v3#arrow-left"></use>
                            </svg>
                        </div>
                        <div className={`swiper-button-next ${titleSlug}-swiper__next`}>
                            <svg className="icon icon-arrow-right">
                                <use xlinkHref="/img/sprite.svg?v3#arrow-right"></use>
                            </svg>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}

function PortalSliderItem<T>({
    item,
    isImgLazyLoading,
    sliderTitleSlug,
    children,
}: {
    item: T;
    isImgLazyLoading: boolean;
    sliderTitleSlug: string;
    children: ReactNode | RenderChildren<T>;
}) {
    return (
        <SwiperSlide
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            onClick={() => {
                trackEvent({
                    action: 'click-slider-item',
                    params: sliderTitleSlug,
                });
            }}
            className="swiper-slide"
            style={{
                width: 'auto!important',
                maxWidth: '90%',
            }}
        >
            {typeof children === 'function' ? children({ item, isImgLazyLoading }) : children}
        </SwiperSlide>
    );
}
